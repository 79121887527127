import axios from '@/config/axios'

// 查询微信订阅配置
export const getWechatSubConfig = () => axios({
  method: 'get',
  url: `/wechatSubConfig`
})

// 添加或修改微信订阅配置
export const addWechatSubConfig = (data) => axios({
  method: 'POST',
  url: `/wechatSubConfig`,
  data
})
