<template>
  <div class="subscribe-wrap">
    <div class="subscribe-box">
      <div class="box-header">
        <div class="main-title">{{details.type | filterType}}</div>
        <div class="box-tool">
          <span @click="submitHandle(0)">取消订阅</span>
        </div>
      </div>
      <div class="box-main">
        <div class="main-info">
          <div class="info-title">接受时间：
            <span class="detail-tool" @click="addTime">添加</span>

          </div>
          <div class="info-detail">
            <span v-for="(item,index) in timeList" :key="index">{{item}}
              <van-icon color="#ee0a24" name="close" @click="removeTime(index)" />
            </span>
          </div>
        </div>
        <div class="main-info">
          <div class="info-title">订阅设备：
            <span class="detail-tool" @click="addDevice" v-if="details.type=='3'||details.type=='4'">添加</span>
          </div>
          <div class="info-detail" v-if="details.type=='3'||details.type=='4'">
            <span v-for="(item,index) in deviceList" :key="item.id">{{item.text}}
              <van-icon color="#ee0a24" name="close" @click="removeDeviece(index)" />
            </span>
          </div>
          <div class="info-detail" v-else>
            <span>全部（默认）</span>
          </div>
        </div>
      </div>
    </div>
    <div class="subscribe-tool">
      <van-button type="info" @click="submitHandle(1)">提交</van-button>
    </div>
    <van-action-sheet v-model="actionShow" title="设备添加">
      <van-tree-select :items="items" :active-id.sync="activeIds" :main-active-index.sync="activeIndex" @click-item='clickItem' />
      <div class="action-tool">
        <van-button style="width:100%" type="default" @click="onSubmit">确定</van-button>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="timeShow" title="选择时间段">
      <van-picker show-toolbar :columns="columns" @confirm='confirmTime' @cancel='timeShow=!timeShow' />
    </van-action-sheet>
  </div>
</template>

<script>
import Vue from 'vue';
import { Icon, TreeSelect, ActionSheet, Picker, Notify } from 'vant';
import "vant/lib/tree-select/style";
import "vant/lib/action-sheet/style";
import 'vant/lib/notify/style'

Vue.use(Icon).use(TreeSelect).use(ActionSheet).use(Picker).use(Notify);

import { addWechatSubConfig } from "@/api/subscribe"
import { getDeviceTypeTree } from '@/api/maintain'

export default {
  name: "Subscribe",
  components: {
  },
  filters: {
    filterType(val) {
      let result = ''
      switch (val) {
        case 1: result = '工单提醒';
          break;
        case 2: result = '工单审批';
          break;
        case 3: result = '设备告警';
          break;
        case 4: result = '工艺告警';
          break;
      }
      return result
    }
  },
  data() {
    function getNumber(x) {
      let number = []
      for (var i = 0; i < x; i++) {
        number.push(i < 10 ? '0' + i : i)
      }
      return number
    }
    return {
      details: null,
      actionShow: false,
      timeList: [],
      deviceList: [],
      items: [],
      activeIds: [],
      activeIndex: 0,
      chidlrenList: [],
      chidlrenKey: {},

      timeShow: false,
      columns: [
        {
          values: getNumber(24),
          defaultIndex: 0,
        },
        {
          values: [':'],
          defaultIndex: 0,
        },
        {
          values: getNumber(60),
          defaultIndex: 0,
        },
        {
          values: ['-'],
          defaultIndex: 0,
        },
        {
          values: getNumber(24),
          defaultIndex: 0,
        },
        {
          values: [':'],
          defaultIndex: 0,
        },
        {
          values: getNumber(60),
          defaultIndex: 0,
        },
      ],
    }
  },

  created() {
    this.getDeviceTypeTree()
    this.details = JSON.parse(this.$route.query.info)
  },
  methods: {
    removeDeviece(index) {
      this.deviceList.splice(index, 1)
    },
    removeTime(index) {
      this.timeList.splice(index, 1)
    },
    getDeviceTypeTree() {
      getDeviceTypeTree().then(res => {
        let list = [{ text: '全部设备', id: '-999', children: [{ text: '全部设备', id: 0 }] }]
        let chidlrenList = [{ text: '全部设备', id: 0 }]
        let chidlrenKey = {}
        chidlrenKey[0] = '全部设备'
        res.details.content.map(item => {
          let chidlren = []
          if (item.deviceChildren.length != '0') {
            item.deviceChildren.map(items => {
              chidlren.push({ text: items.deviceName + '(' + items.deviceNo + ')', id: items.id })
              chidlrenKey[items.id] = items.deviceName
            })
          }
          list.push({ text: item.label, id: item.id, children: chidlren })
          chidlrenList = chidlrenList.concat(chidlren)
        })
        this.items = list
        this.chidlrenList = chidlrenList
        this.details.deviceIds.map(item => {
          this.deviceList.push({
            text: chidlrenKey[item],
            id: item
          })
        })
        this.timeList = this.details.timeList
      })
    },
    clickItem(data) {
      if (this.activeIds.includes(0)) {
        if (data.id != '0') {
          Notify({ type: 'danger', message: '已选择全部设备' });
        }
        this.activeIds = [0]
      }

    },
    addTime() {
      this.timeShow = true
    },
    confirmTime(data) {
      let time = ''
      data.map(item => {
        time += item
      })
      this.timeList.push(time)
      this.timeShow = false

    },
    addDevice() {
      this.actionShow = true
      this.activeIds = []
      this.activeIndex = 0
      this.deviceList.map(item => {
        this.activeIds.push(Number(item.id))
      })
    },
    onSubmit() {
      this.deviceList = []
      this.chidlrenList.map(item => {
        if (this.activeIds.includes(item.id)) {
          this.deviceList.push(item)
        }
      })
      this.actionShow = false
    },
    submitHandle(isSub = 1) {
      let deviceIds = []
      this.deviceList.map(item => {
        deviceIds.push(item.id)
      })
      let timeList = this.timeList
      let params = {
        id: this.details.id,
        type: this.details.type,
        deviceIds: deviceIds.join(','),
        timeList,
        isSub
      }
      addWechatSubConfig(params).then(res => {
        this.$router.go(-1)
      })
    },


  }
}
</script>

<style scoped lang="scss">
@import '../../style/mixin';
.subscribe-wrap {
  width: 100%;
  height: 100%;
  background-color: #f1f1f1;
  .subscribe-box {
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    width: 100%;
    background: #fff;
    .box-header {
      @include fd();
      border-bottom: 1px solid #ebedf0;
      position: relative;
      padding-bottom: 0.2rem;
      align-items: center;

      .main-title {
        position: relative;
        font-size: 0.8rem;
        font-weight: bold;
        flex: 1;
      }

      .main-title::before {
        position: absolute;
        top: 50%;
        left: -0.75rem;
        width: 0.15rem;
        height: 0.8rem;
        background-color: #1989fa;
        transform: translateY(-50%);
        content: '';
      }
      .box-tool {
        display: inline-flex;
        span {
          color: #999;
          font-size: 0.8rem;
          font-weight: bold;
          vertical-align: text-top;
        }
      }
    }
    .box-main {
      .main-info {
        width: 100%;
        padding-top: 0.5rem;
        .info-title {
          font-size: 0.7rem;
          font-weight: bold;
          color: #1989fa;
          .detail-tool {
            padding: 0.2rem 0.4rem;
            background: #1989fa;
            border-radius: 0.2rem;
            color: #fff;
            font-size: 0.6rem;
            font-weight: bold;
          }
        }

        .info-detail {
          padding: 0.2rem 0.5rem;
          span {
            display: block;
            margin: 0.4rem 0;
            color: #333;
            font-weight: bold;
            font-size: 0.7rem;

            .van-icon {
              margin-left: 0.5rem;
              vertical-align: middle;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  /deep/.subscribe-tool {
    position: fixed;
    bottom: 0px;
    width: 100%;
    .van-button {
      width: 100%;
      .van-button__content {
        .van-button__text {
          color: #fff !important;
        }
      }
    }
  }
}
</style>
